import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/layout.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/home.vue'),
      },
      {
        path: '/solution',
        name: 'solution',
        meta: {
          title: '解决方案',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/solution.vue'),
      },
      {
        path: '/campusSolutions',
        name: 'campusSolutions',
        meta: {
          title: '园区解决方案',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/campusSolutions.vue'),
      },
      {
        path: '/caseCenter',
        name: 'caseCenter',
        meta: {
          title: '案例中心',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/caseCenter.vue'),
      },
      {
        path: '/restaurant',
        name: 'restaurant',
        meta: {
          title: '餐饮管理解决方案',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/restaurant.vue'),
      },
      {
        path: '/caseDetail',
        name: 'caseDetail',
        meta: {
          title: '案例详情页',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/caseDetail.vue'),
      },
      {
        path: '/productCenter',
        name: 'productCenter',
        meta: {
          title: '产品中心',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/productCenter.vue'),
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: {
          title: '关于我们',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs.vue'),
      },
      {
        path: '/scenicSpotSolution',
        name: 'scenicSpotSolution',
        meta: {
          title: '智慧景区解决方案',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/scenicSpotSolution.vue'),
      },
      {
        path: '/distribution',
        name: 'distribution',
        meta: {
          title: '智慧分销解决方案',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/distribution.vue'),
      },
      {
        path: '/property',
        name: 'property',
        meta: {
          title: '智慧物业解决方案',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/property.vue'),
      },
      {
        path: '/cooperate',
        name: 'cooperate',
        meta: {
          title: '申请合作',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/cooperate.vue'),
      }
    ]
  },
  
  
]

const router = new VueRouter({
  routes
})

export default router
