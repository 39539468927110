export default {
  /*表单校验电话号码*/ 
  checkMobile (rule, value, callback)  {
    if (value != '') {
      const regMobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确的的手机号'))
    } else {
      callback();
    }
  },
  /*email校验*/
  checkEmail (rule, value, callback) {
    if (value != '') {
      let emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (emailReg.test(value)) {
        return callback()
      }
      callback(new Error('请输入正确格式的邮箱'))
    } else {
      callback();
    }
  },
}