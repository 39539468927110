import Vue from 'vue'
import App from './App.vue'
import _ from 'lodash'
import './assets/js/format';
import api from './api/api'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import animated from 'animate.css'
Vue.prototype.$api = api
Vue.prototype._ = _
Vue.use(ElementUI, { size: 'small'})
// Vue.use(animated)
import bus from './assets/js/bus'
Vue.prototype.$bus = bus
import common from './assets/js/common.js'
Vue.prototype.$common = common

Vue.config.productionTip = false


import {
  WOW
} from 'wowjs'
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 50, // default
  mobile: true, // default
  live: false,
 
  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
 
  callback: function (box) {
    // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	next()
	
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
