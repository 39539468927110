import axios from 'axios'
import {baseUrl} from './url'

let requests = axios.create({
	baseURL: baseUrl,
	// timeout: 5000
})
requests.interceptors.request.use((config) => {
	return config

})

requests.interceptors.response.use(
	(res) => {
		// if (res.data.code == 401) {
		// 	Message.closeAll()
		// 	Message.error(res.data.message)
		// 	sessionStorage.clear()
		// 	router.push('/login');
		// } else {
			return res.data
		// }

	},
	(err) => {
		console.error("请求错误:" + err)
	}
)

export default requests
